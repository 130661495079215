//create a container component that will hold the other components
import React, { useEffect } from 'react';
//load LiveViewContainerHelper
import LiveViewContainerHelper from '../helpers/LiveViewContainerHelper';
function Container() {
    //set message state
    useEffect(() => { 
        document.body.style.margin = '0';
        document.body.style.overflow = "scroll";
        const liveviewHelper = new LiveViewContainerHelper({
            key: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZXJjaGFudHMiOlt7Im1lcmNoYW50aWQiOiI5MSIsImNvdW50cnljb2RlIjoiVVMifSx7Im1lcmNoYW50aWQiOiIyMiIsImNvdW50cnljb2RlIjoiR0IifSx7Im1lcmNoYW50aWQiOiIxMTEiLCJjb3VudHJ5Y29kZSI6IkRFIn0seyJtZXJjaGFudGlkIjoiMTIiLCJjb3VudHJ5Y29kZSI6IlVTIn1dLCJpYXQiOjE3MjQxNTk3NDUsImV4cCI6MTc1NTY5NTc0NX0.i2DtM2XsQpPi9WiLrcYlO-gYoqv48g7sg_YqXmd0HqA',
            keytype : 1, //JWT
            iframeName: 'liveViewIframe',
          });                  
      }, []);

 
    return (
        <div>
        <h1>Parent Container Page</h1>
        <iframe
          id="liveViewIframe"
          src="/liveview"
          title="Live View"
          style={{ width: '100%', border: 'none' }}
        ></iframe>
      </div>
    );
}

export default Container;