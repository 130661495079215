//create a container component that will hold the other components
import React, { useEffect, useState } from 'react';
//import liveview.css
import styles from '../css/liveview.module.css';
import LiveViewHelper from '../helpers/LiveViewHelper';
function LiveView() {
    //set message state
    const [message, setMessage] = useState('Waiting for data...');
    const [merchantData, setMerchantData] = useState([]);
    const liveviewHelper = new LiveViewHelper();

    useEffect(() => {
        liveviewHelper.init().then((data) => {          
            if (!data.error){
                setMessage("Data Loaded");
                setMerchantData(data);
            }
            else {
                setMessage(data.error);
            }
        });
    
        // Cleanup listener on component unmount
        return () => liveviewHelper.releaseListener();
      }, []);

 
      const encryptData = () => {
        //get the selected encryption type
        const encryptType = document.querySelector('input[name="encrypt"]:checked').value;
       //get the merchant id and country code
        const merchantId = document.getElementById('merchant_id').value;
        const countryCode = document.getElementById('country_code').value;
 
        //call api/test_encrypt with the merchant id and country code and the encryption type
        fetch('/api/test_encrypt', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ merchantId, countryCode, encryptType }),
        })
            .then((response) => response.json())
            .then((data) => {
                //set the encrypted data in the textarea
                document.getElementById('enc_data').value = data.encryptedData;
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    
      }

      const decryptData = () => {
        //get the selected encryption type
        const encryptType = document.querySelector('input[name="encrypt"]:checked').value;
        //get the token
        const token = document.getElementById('token').value;
 
        //call api/test_decrypt with the token and the encryption type
        fetch('/api/test_decrypt', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ encryptedData: token, encryptType }),
        })
            .then((response) => response.json())
            .then((data) => {
                //build a simple html from data.decryptedData merchants array
                let html = '<table><tbody>';
                for (let i = 0; i < data.decryptedData.merchants.length; i++) {
                    const merchant = data.decryptedData.merchants[i];
                    html += `<tr><td>Merchant ID: <b>${merchant.merchantId}</b></td></tr><tr><td>Country: <b>${merchant.countryCode}</b></td></tr>`;
                }
                html += '</tbody></table>';
                //set the decrypted data in the textarea

                document.getElementById('dec_data').innerHTML = html;
            })
            .catch((error) => {
                console.error('Error:', error);                
            });
        }

      
    return (
        <div className={styles.liveViewContainer}>
        <h1>Live Dashboard</h1>
        <div id="dataDisplay">{message}</div>
        <div id="merchantsList">
            <table className={styles.merchants_table}><tbody>
            {merchantData.merchants && merchantData.merchants.map((merchant) => (
                <tr key={merchant.merchantId} className={styles.merchant}>
                <td>Merchant ID: {merchant.merchantId}</td>
                <td>Country: {merchant.country}</td>
                <td>Timezone: <b>now</b>:{merchant.timezone.now} <span style={{marginRight : "20px"}}></span> <b>midnight</b>: {merchant.timezone.midnight}</td>
                </tr>
            ))}
          </tbody></table>
        </div>
        <div>
            <h1>Test Encryption / Decryption</h1>
            <input type="radio" id="encrypt_aes" name="encrypt" value="AES" />
            <label htmlFor="encrypt_aes">AES</label>
            <input type="radio" id="encrypt_jwt" name="encrypt" defaultChecked value="JWT" />
            <label htmlFor="encrypt_jwt">JWT</label>
            <br />
            <div className={styles.cont_encrypt}>
            <table>
            <tbody>
                <tr>
                    <td colSpan={2}>Enter Merchant Data</td>
                </tr>
                <tr>
                    <td>Merchant ID</td>
                    <td><input type="text" id="merchant_id" /></td>
                </tr>
                <tr>
                    <td>Country Code</td>
                    <td><input type="text" id="country_code" /></td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <textarea id="enc_data" style={{width:"300px", height:"100px", marginTop:"20px"}}></textarea>
                    </td>
                </tr>
            </tbody>
            </table>
            <button onClick={encryptData} className={styles.decenc_button} id="encrypt_button">Encrypt</button>
            </div>

            <div className={styles.cont_decrypt}>
            <table>
            <tbody>
                <tr>
                    <td colSpan={2}>Enter Token</td>
                </tr>
                <tr>
                    <td><input type="text" style={{width:"300px"}} id="token" /></td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <div id="dec_data" style={{width:"300px", height:"100px", marginTop:"20px"}}></div>
                    </td>
                </tr>      
            </tbody>          
            </table>
            <button onClick={decryptData} className={styles.decenc_button} id="decrypt_button">Decrypt</button>
            </div>

        </div>
      </div>
    );
}

export default LiveView;