 
class LiveViewHelper {
    merchantData = null;
    self = this;
    constructor() {
        this.listenToIncomingMessages();
    }

    listenToIncomingMessages() {
        //window.addEventListener('message', this.handlePostMessage);
        //use arrow function to bind this
        window.addEventListener('message', (event) => { 
            this.handlePostMessage(event, this);
        });

        //register listener for window size change
        window.addEventListener('resize', (event) => { 
            this.handleResize();                
        });

        this.handleResize();
    }

    releaseListener() {
        window.removeEventListener('message', this.handlePostMessage)
    }

    init() {
        //return promise
        //post a READY message to the parent window
        window.parent.postMessage({ type: 'LIVE_VIEW_MESSAGE', message: 'READY' }, window.location.origin);
        return new Promise((resolve, reject) => {
            //wait for merchantLoaded to be true
            const si = setInterval(() => {
                if (this.merchantData) {
                    clearInterval(si);
                    resolve(this.merchantData);
                }
            }, 1000);
        });
    }
    handleResize() {
        //post messate to container 
        // Get the iframe's content size
        var width = document.documentElement.scrollWidth;
        var height = document.documentElement.scrollHeight;

        window.parent.postMessage({ type: "LIVE_VIEW_MESSAGE", message:"RESIZE", dims : { width: width, height: height} }, "*");
    }
    handlePostMessage(event, self) {
        if (event.origin !== window.location.origin) {
            // Ensure the message is from a trusted source
            return;
          }

          if (event.data.type !== 'ENCRYPTED_DATA') {
            // Ignore messages that are not of the expected type
            return;
          }
    
          const encryptedData = event.data.key;
          const keyType = event.data.keytype;
          //console.log('Decrypted Data:', encryptedData); 
          self.fetchMerchantData(encryptedData,keyType);
    }

    
       fetchMerchantData = async (encryptedKey,keyType) => {     
        const url = `${process.env.REACT_APP_SERVER_BASE_URL}/merchantdata`      
         var data = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ data: encryptedKey, keyType: keyType }),
            }) 

            var response = await data.json();
            this.merchantData = response;
      
      }
}

export default LiveViewHelper;